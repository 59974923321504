import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { FaEnvelope } from 'react-icons/fa'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from "react-redux"

import "./union-member-single.scss"

import CheckboxInput from "../containers/singleInformation/welcome/checkboxInput"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import { onChangeInformation, onChange, onChangeByName } from "../redux/registration/registrationAction"
import { checkMember } from "../redux/api/apiAction"
import { isDefined } from "../utils/data"
import HelpUr from "../components/help/help"

const UnionMemberSinglePage = () => {
    const dispatch = useDispatch()
    const { information } = useSelector(({ registrationReducer }) => registrationReducer)
    const errorAPI = useSelector(({ apiReducer }) => apiReducer.login_failed)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [email, setEmail] = useState("")
    const [validationEmail, setValidationEmail] = useState("")



    const checkValidation = () => {
        if (email === "")
            setValidationEmail("Enter your email")
        else
            setValidationEmail("")

        return email.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        const { value } = e.target
        setEmail(value)

        if (checkValidation()) {
            dispatch(checkMember(email))
                .then(res => {
                    if (res === true) {
                        setEmail("")
                        setSuccess(true)
                        setError(false)
                        dispatch(onChangeByName("unionMember", true))
                        navigate("/individual")
                    }
                    else {
                        setEmail(email)
                        setSuccess(false)
                        setError(true)
                        dispatch(onChangeByName("Delegate", true))
                        navigate("/individual")
                    }
                })
        }
        else {
            setSuccess(false)
            setError(true)
        }
    }

    return (
        <Layout>
            <SEO title="Registration" />
            <div className={"col-10 mx-auto"}>


                <div className={"form-row"}>
                    <div className={"col-12"}>
                        <p>Registration closed</p>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default UnionMemberSinglePage
